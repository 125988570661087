import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"

export default function Home({ data }) {
  console.log(data.allMarkdownRemark.edges);
  return <Layout>
    <div>
    <h4>{data.allMarkdownRemark.totalCount} Posts</h4>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <div key={node.id}>
            <Link to={node.fields.slug}>
            <h3>{node.frontmatter.title}</h3></Link>
            <span><i>{node.frontmatter.date}</i></span>
            <p>{node.excerpt}</p>
          </div>
        ))}
    </div>
    </Layout>
}

export const query = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { draft: { ne: true }}} sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title,
            date(formatString: "DD MMMM, YYYY")
          }
          excerpt
        }
      }
    }
  }
`